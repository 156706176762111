import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/store'

import { UpdateSettingsMutationVariables } from 'gql'

const selectOnboarding = (s: RootState) => s.onboarding

export const selectOnboardingOpen = createSelector([selectOnboarding], (ob) => ob.open)
export const selectOnboardingPunted = createSelector([selectOnboarding], (ob) => ob.punted)

export const selectInitialOnboardingData = createSelector([selectOnboarding], (ob) => ob.initial)
export const selectCurrentOnboardingData = createSelector([selectOnboarding], (ob) => ob.current)

export const selectOnboardingMutationVars = createSelector(
  [selectInitialOnboardingData, selectCurrentOnboardingData],
  (initial, current): UpdateSettingsMutationVariables | void => {
    if (initial && current) {
      const vars: UpdateSettingsMutationVariables = {}
      if (current.policies.effectChange) {
        vars.enableRefund = current.policies.refund
        vars.enableExchange = current.policies.exchange
        if (vars.enableExchange) vars.isExchangeIntegrationEnabled = true
        vars.enableStoreCredit = current.policies.credit
      }
      if (current.policies.exchange && current.exchanges.effectChange) vars.exchangeMethod = current.exchanges.type
      if (current.shipping.effectChange) {
        vars.shipFree = current.shipping.prepaid
        vars.shipPay = current.shipping.payForLabel
        vars.shipSelf = current.shipping.self
      }
      if (current.address.effectChange) {
        const address = { ...current.address }
        delete address.effectChange
        vars.defaultAddress = address
      }
      if (current.approval.effectChange) {
        vars.useApprovals = current.approval.type === 'review'
        vars.requireApprovalForAllReturns = current.approval.type === 'review'
      }
      vars.onboardingComplete = true
      return vars
    }
  },
)
