import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import en from 'translations/en/admin.json'

import { AuthenticatedShop, ShopifyUser } from 'gql'
import { detectLocale, LocaleId } from 'intl/intl'

import { defaultShop, defaultShopifyUser } from './defaults'

export interface GlobalSlice {
  fetching: boolean
  detectedLocale: LocaleId
  preferredLocale?: LocaleId | null
  messages: typeof en
  messagesLocale: LocaleId
  shop: AuthenticatedShop
  loaded: (
    | 'billing'
    | 'common'
    | 'dashboard'
    | 'edit-notification'
    | 'integration'
    | 'language'
    | 'notifications'
    | 'onboarding'
    | 'policy'
    | 'reasons'
    | 'portal'
    | 'customizer'
    | 'returns'
    | 'rules'
    | 'shipping'
    | 'workflow'
  )[]
  user?: ShopifyUser
  error?: string
  notAuthenticated: boolean
  toastMessage: string
  beaconVisible: boolean
}

const storeValue = (name: keyof AuthenticatedShop, object?: unknown | null) => {
  try {
    if (object !== undefined) localStorage.setItem(`rz-shop-${name}`, JSON.stringify(object))
  } catch {
    /* empty */
  }
}

const initialState: GlobalSlice = {
  fetching: false,
  detectedLocale: detectLocale(),
  messages: en,
  messagesLocale: 'en',
  shop: {
    ...defaultShop,
    forceSelectSubscriptionPlan: false,
    onboardingComplete: true,
  },
  loaded: [],
  user: defaultShopifyUser,
  notAuthenticated: false,
  toastMessage: '',
  beaconVisible: false,
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setFetching: (state, { payload: fetching }: PayloadAction<boolean>) => {
      state.fetching = fetching
    },
    setPreferredLocale: (state, { payload: preferredLocale }: PayloadAction<LocaleId | null>) => {
      state.preferredLocale = preferredLocale
    },
    setMessages: (
      state,
      { payload: { locale, messages } }: PayloadAction<{ locale: LocaleId; messages: typeof en }>,
    ) => {
      state.messages = messages
      state.messagesLocale = locale
    },
    updateShop: (state, { payload: shop }: PayloadAction<Partial<AuthenticatedShop>>) => {
      storeValue('forceSelectSubscriptionPlan', shop.forceSelectSubscriptionPlan)
      storeValue('onboardingComplete', shop.onboardingComplete)
      state.shop = {
        ...defaultShop,
        ...state.shop,
        ...shop,
      }
    },
    setLoaded: (state, { payload: loaded }: PayloadAction<GlobalSlice['loaded'][0]>) => {
      if (!state.loaded.includes(loaded)) state.loaded.push(loaded)
    },
    setUser: (state, { payload: user }: PayloadAction<ShopifyUser>) => {
      state.user = user
    },
    setError: (state, { payload: error }: PayloadAction<string | undefined>) => {
      state.error = error
    },
    showToast: (state, { payload: toastMessage }: PayloadAction<string>) => {
      state.toastMessage = toastMessage
    },
    setBeaconVisible: (state, { payload: visible }: PayloadAction<boolean>) => {
      state.beaconVisible = visible
    },
  },
})

export const {
  setPreferredLocale,
  setMessages,
  updateShop,
  setLoaded,
  setUser,
  setError,
  showToast,
  setBeaconVisible,
} = globalSlice.actions

export default globalSlice.reducer
